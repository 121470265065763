import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { DataContextProvider } from "./Data.context";
import { MapContextProvider } from "./Map.context";

import AppLayout from "./AppLayout";
import { useCurrentHeight } from "./utils/hooks/useResize";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0072EC",
    },
    secondary: {
      main: "#0072EC",
    },
  },
});

export default function App() {
  let height = useCurrentHeight();

  if (document) {
    const $root = document.getElementById("root");
    // $root && $root.style.height = `${window.innerHeight}px`;
    if ($root) {
      $root.style.height = `${height}px`;
    }
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <DataContextProvider>
          <MapContextProvider>
            <AppLayout />
          </MapContextProvider>
        </DataContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}
