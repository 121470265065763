import { makeStyles } from "@material-ui/core/styles";
import useQuery from "./utils/hooks/useQuery";

import DistributionPanel from "./components/DistributionPanel";
import FilterPanel from "./components/FilterPanel";
import Map from "./components/Map";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: "100%",
    maxWidth: 3024,
    margin: "0 auto",
    position: "relative",
    overflow: "hidden",
  },
}));

export default function AppLayout() {
  const classes = useStyles();
  const query = useQuery();
  const hideFilters = !!query.get("hideFilters");
  const hideDistribution = !!query.get("hideDistribution");
  return (
    <div className={classes.wrapper}>
      {!hideFilters && <FilterPanel />}
      {!hideDistribution && <DistributionPanel />}
      <Map filtersHidden={hideFilters} />
    </div>
  );
}
