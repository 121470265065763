import { LngLatLike } from "./../constants";

export const flyTo = (map: any, position: LngLatLike, isActive: boolean) => {
  if (!map) {
    return;
  }

  if (!position) {
    if ((map.current?.getZoom() || 0) === 2.2) {
      map.current?.flyTo({
        speed: 0.4,
        zoom: 0,
        essential: true,
      });
    }
  }

  if ((map.current?.getZoom() || 0) < 2.2 && !isActive) {
    map.current?.flyTo({
      speed: 0.4,
      zoom: 2.2,
      center: position,
      essential: true,
    });
  } else {
    map.current?.flyTo({
      speed: 0.4,
      center: position,
      essential: true,
    });
  }
};
