import { LngLat } from "mapbox-gl";

export enum Breakpoints {
  Mobile = 768,
}

export enum Quantile {
  "low" = "25",
  "high" = "75",
}

export enum Category {
  Import = "Import",
  Export = "Export",
  Berthing = "Berthing",
  Anchor = "Anchor",
  Vessel = "Vessel",
}

export enum Metric {
  Median = "q50",
  Quantile25 = "q25",
  Quantile75 = "q75",
}


export enum MetricReadable {
  Median = "Median Dwell (days)",
  Quantile25 = "25% Quantile Dwell (days)",
  Quantile75 = "75% Quantile Dwell (days)",
}

export type LngLatLike =
  | [number, number]
  | LngLat
  | { lng: number; lat: number }
  | { lon: number; lat: number };


export enum LegendColor
{
  Blue4 = "#094c72",
  Blue3 = "#026aa7",
  Blue2 = "#298fca",
  Blue1 = "#8bbdd9",
  Yellow = "#f6ae2d",
  Red = "#CA4236"
}

export enum ResolutionReadable {
  'LW' = 'Last Week',
  'LM' = 'Last Month',
  'LQ' = 'Last 3 Months',
}

export enum Resolution {
  'LW' = 'Last7',
  'LM' = 'Last30',
  'LQ' = 'Last90',
}
