import { useContext } from "react";

import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { DataContext } from "../../Data.context";
import { getSegmentEnds } from "./../../utils/calc/algo";
import {
  zIndexLevels,
  LEGEND_COLORS_HEX,
  legendConfigArray,
  LegendBreakdown,
} from "./../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flex: "1 1 100%",
    padding: 0,
    position: "relative",
    backgroundColor: "#ffffff",
    zIndex: zIndexLevels.panels,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      flex: "1 1 450px",
      minWidth: 450,
      borderRadius: 3,
    },
  },
  legendChart: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(2),
    },
  },
  legendKeyCount: {
    color: "rgba(0,0,0,.9)",
    background: "#ffffff",
    padding: "4px 7px",
    fontSize: 12,
    margin: "0",
    justifyContent: "start",
    height: "100%",
    flexDirection: "column",
    display: "none",

    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
  legendKey: {
    flex: "1 1 15%",
    display: "flex",
    minWidth: "50px",
    flexDirection: "column",
    borderLeft: "1px solid rgba(255,255,255,0.2)",

    [theme.breakpoints.up("md")]: {
      flex: "1 1 auto",
      marginRight: 8,
    },

    [theme.breakpoints.up("lg")]: {},
  },
  legendKeyCountDesc: {
    fontWeight: "bold",
    width: "100%",
    alignItems: "start",
    justifyContent: "end",
    flexDirection: "column",
    display: "none",

    [theme.breakpoints.up("md")]: {
      alignItems: "start",
      justifyContent: "start",
      marginRight: theme.spacing(2),
    },

    "& p ": {
      margin: 0,
    },
  },
  legendKeyColor: {
    width: "100%",
    height: "30px",
    margin: 0,
    color: "#ffffff",
    fontWeight: "bold",
    padding: 3,
    borderRadius: 0,

    [theme.breakpoints.up("md")]: {
      height: "10px",
      borderRadius: 5,
    }
  },
}));

export default function Legend(props: any) {
  const classes = useStyles();
  const { dataset, selectedMetric } = useContext(DataContext);

  const generateLegendBlock = (
    value: string,
    index: number,
    breakdown: Array<LegendBreakdown>
  ) => {
    const status = getSegmentStatusHTML(index);
    return (
      <div className={classes.legendKey} key={JSON.stringify(value) + index}>
        <div className={classes.legendKeyCountDesc}>{status}</div>

        <div className={classes.legendKeyCount}>
          {Number(breakdown[index]?.low).toFixed(1)} -{" "}
          {Number(breakdown[index]?.high).toFixed(1)} ( days )
        </div>
        <p
          style={{ backgroundColor: value }}
          className={classes.legendKeyColor}
        ></p>
      </div>
    );
  };

  const getSegmentStatusHTML = (index: number) => {
    const config = legendConfigArray[legendConfigArray.length - (index + 1)];
    if (!config) return [];

    if (!config.showLimit) {
      return <p>{config.label}</p>;
    } else {
      return (
        <>
          <p>
            {config.statA}
            <sup>th</sup> - {config.statB}
            <sup>th</sup>
          </p>
        </>
      );
    }
  };

  const segmentBreakdown = getSegmentEnds(dataset, selectedMetric);

  return (
    <section className={classes.wrapper}>
      <section className={classes.legendChart}>
        {LEGEND_COLORS_HEX &&
          LEGEND_COLORS_HEX.map((value: string, i: number) => {
            return generateLegendBlock(value, i, segmentBreakdown);
          })}
      </section>
    </section>
  );
}
