import { Resolution, ResolutionReadable, LngLatLike } from "./types";

export const MinNormalizedBubbleSize = 4;
export const MaxNormalizedBubbleSize = 20;

export const zIndexLevels = {
  loading: 9999,
  panels: 1,
  map: 0,
  summary: 2,
};

export const DATE_FILTER_OPTS = [
  { label: ResolutionReadable.LW, value: Resolution.LW },
  { label: ResolutionReadable.LM, value: Resolution.LM },
  { label: ResolutionReadable.LQ, value: Resolution.LQ },
];

export const legendConfigArray = [
    {
      limits: [0, 10],
      label: 'Highest 10%',
      description: 'This group represents the Highest 10% of ports',
      showLimit: false,
    },
    {
      limits: [10.01, 20],
      label: 'percentile of ports',
      showLimit: true,
      description: 'This group represents 10th to 20th percentile of ports',
      statA: 10,
      statB: 20,
    },
  
    {
      limits: [20.01, 40],
      label: 'percentile of ports',
      description: 'This group represents 20th to 40th percentile of ports',
      showLimit: true,
      statA: 20,
      statB: 40,
    },
    {
      limits: [40.01, 60],
      label: 'percentile of ports',
      description: 'This group represents 40th to 60th percentile of ports',
      showLimit: true,
      statA: 40,
      statB: 60,
    },
    {
      limits: [60.01, 80],
      label: 'percentile of ports',
      description: 'This group represents 60th to 80th percentile of ports',
      showLimit: true,
      statA: 60,
      statB: 80,
    },
  
    {
      limits: [80.01, 100],
      description: 'This group represents the Lowest 20% of ports',
      showLimit: false,
      label: 'Lowest 20%',
    },
  ];
  
  export const legendSegmentPercentageMapping = legendConfigArray.map(
    (seg) => seg.limits,
  );

  export const mapCenterCoord: LngLatLike = [10.8867, 15.1262]; // in Africa, roughly the center of a flat earth
