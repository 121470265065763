import { useContext } from "react";

import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { DataContext } from "../../../../Data.context";
import { MapContext } from "../../../../Map.context";

import {
  FeatureCollection,
  MapSummary,
  zIndexLevels,
} from "./../../../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    flex: "1 1 450px",
    padding: 0,
    width: "100%",
    position: "relative",
    backgroundColor: "#ffffff",
    zIndex: zIndexLevels.panels,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      minWidth: 450,
    },
  },
  autocomplete: {
    width: "100%",
  },
  input: {
    width: "100%",
  },
}));

export default function SearchAutoComplete(props: any) {
  const classes = useStyles();
  const { dataset, selectedPort, onSelectPort } = useContext(DataContext);
  const { onMapSelectPort } = useContext(MapContext);

  const selectPort = (summary: MapSummary) => {
    onSelectPort && onSelectPort(summary);
    onMapSelectPort && onMapSelectPort(summary);
  };

  return (
    <section className={classes.wrapper}>
      <Autocomplete
        id="port-search"
        options={dataset}
        value={
          dataset.find(
            (x: FeatureCollection) =>
              x.properties?.portUnLocode === selectedPort?.portUnLocode
          ) || null
        }
        getOptionLabel={(option: FeatureCollection) =>
          (option.properties || {}).name || ""
        }
        onChange={(e: any, val: any) => {
          selectPort((val as FeatureCollection).properties);
        }}
        className={classes.autocomplete}
        renderInput={(params: any) => (
          <TextField
            {...params}
            name="portUnLocode"
            label="Search"
            variant="outlined"
            className={classes.input}
          />
        )}
      />
    </section>
  );
}
