import React from "react";
import { Breakpoints } from "./../constants"

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    const isMobileView = mobile || window.innerWidth < Breakpoints.Mobile;
    setMobile(isMobileView);
  }, []);

  return { isMobile };
}