import { useState, useEffect } from 'react';

const getHeight = () => window.innerHeight || 0;

export function useCurrentHeight() {
  let [height, setHeight] = useState(getHeight());
  
  useEffect(() => {
    const resizeListener = () => {
      setHeight(getHeight())
    };

    window.addEventListener('resize', resizeListener);
    window.addEventListener('fullscreenchange', resizeListener);

    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('fullscreenchange', resizeListener);
    }
  }, [])

  return height;
}