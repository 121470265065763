import {
  legendSegmentPercentageMapping,
  MaxNormalizedBubbleSize,
  MinNormalizedBubbleSize,
  LegendBreakdown,
  FeatureCollection,
  Metric,
} from "./../constants";
import { ascSort } from "./sorting";

export const normalizeNx = (value: number, min: number, max: number) => {
  return (
    MinNormalizedBubbleSize +
    ((MaxNormalizedBubbleSize - MinNormalizedBubbleSize) *
      (Math.sqrt(value) - Math.sqrt(min))) /
      (Math.sqrt(max) - Math.sqrt(min))
  );
};

export const getSegmentEnds = (
  data: Array<FeatureCollection>,
  metric: Metric
): Array<LegendBreakdown> => {
  const segmentArr: Array<LegendBreakdown> = [];
  if (data && data.length > 0) {
    const arrayDwell = data
      .map((d: any) => {
        const dwell = (d as any).properties[metric];
        if (dwell !== null && Number.isFinite(dwell)) {
          return dwell;
        }
      })
      .filter(function (x) {
        return x !== undefined;
      });

    arrayDwell.sort(ascSort);

    const count = arrayDwell.length;
    for (let i = 0; i < legendSegmentPercentageMapping.length; i++) {
      const upperLimit = legendSegmentPercentageMapping[i][1];
      const lowerLimit = legendSegmentPercentageMapping[i][0];
      const upperNodeIndex = Number((upperLimit / 100) * count).toFixed(0);
      const lowerNodeIndex = Number((lowerLimit / 100) * count).toFixed(0);
      const low = arrayDwell[parseInt(lowerNodeIndex)];
      const high = arrayDwell[parseInt(upperNodeIndex) - 1];
      segmentArr.push({ high, low });
    }
  }
  return segmentArr;
};
