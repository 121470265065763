import { useContext } from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Metric } from "./../../utils/constants";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { DataContext } from "../../Data.context";
import { MapContext } from "../../Map.context";
import { zIndexLevels } from "./../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "absolute",
    zIndex: zIndexLevels.summary,
    width: `calc(100% - ${theme.spacing(2)})px`,
    display: "flex",
    top: `${theme.spacing(1)}px`,
    left: theme.spacing(1),
    right: `${theme.spacing(3)}px`,
    background: "#fff",
    borderRadius: 5,
    padding: 0,

    [theme.breakpoints.up("sm")]: {
      width: 350,
    },

    [theme.breakpoints.up("md")]: {
      width: 350,
    },
  },
  actionArea: {
    display: "flex",
    justifyContent: "end",
    padding: theme.spacing(1),
  },
  popupRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardsHeader: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  cardsHeaderTitle: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(0, 0, 0, 2),
  },
  cardsHeaderPortname: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(0, 0, 2, 0),

    "& span": {
      display: "block",
    },
  },
  cardsHeaderPortcode: {
    margin: theme.spacing(0, 0, 0, 2),
  },
  cardContentWrapper: {
    position: "relative",
  },
  detailList: {
    display: "flex",
    flexDirection: "row",
    minHeight: 100,
    justifyContent: "space-around",
    alignItems: "start",
  },
  rootList: {
    width: "100%",
  },
  segmentBreakdownContainer: {
    margin: 0,
    fontSize: 14,
    padding: 0,
  },
  segmentBreakdownTitle: {
    color: theme.palette.grey[500],
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: "bold",
    padding: 0,

    "&.MuiGrid-item": {
      padding: 0,
    },
  },
  segmentBreakdown: {
    display: "flex",
    justifyContent: "end",
    color: theme.palette.grey[700],
    padding: 0,

    "&.MuiGrid-item": {
      padding: 0,
    },
  },
  segmentBreakdownVal: {
    fontWeight: "bold",
    margin: theme.spacing(0, 2, 0, 0),
  },
  viewDetailsBtn: {
    fontSize: 12,
    width: "100%",
    padding: theme.spacing(1),
    borderColor: "#D5D7D9",
    color: "#0A1521",
  },
}));

export default function DetailsCard(props: any) {
  const classes = useStyles();
  const {
    selectedPort,
    selectedMetric,
    selectedDateRange,
    mapApiResponse,
    onSelectPort,
  } = useContext(DataContext);

  const { onMapSelectPort } = useContext(MapContext);

  const summary = mapApiResponse.find(
    (a: any | undefined) => a?.locode === selectedPort?.portUnLocode
  ) as any;

  const NO_DATA_MSG = "No Data Available";

  const dwellR = `dwell${selectedDateRange}`;
  const berthingR = `berthing${selectedDateRange}`;
  const anchorR = `anchor${selectedDateRange}`;
  const vesselR = `approaching${selectedDateRange}`;

  let podMedian;
  let polMedian;
  let berthingMedian;
  let anchorMedian;
  let vesselCount;

  if (selectedPort && summary) {
    const dwellSummary = summary[dwellR] || ({} as any);
    const berthingSummary = summary[berthingR] || ({} as any);
    const anchorSummary = summary[anchorR] || ({} as any);

    switch (selectedMetric) {
      case Metric.Median:
        podMedian = dwellSummary.podMedian;
        polMedian = dwellSummary.polMedian;
        berthingMedian = berthingSummary.median;
        anchorMedian = anchorSummary.median;
        break;
      case Metric.Quantile25:
        podMedian = dwellSummary.podQ1;
        polMedian = dwellSummary.polQ1;
        berthingMedian = berthingSummary.q1;
        anchorMedian = anchorSummary.q1;

        break;
      case Metric.Quantile75:
        podMedian = dwellSummary.podQ3;
        polMedian = dwellSummary.polQ3;
        berthingMedian = berthingSummary.q3;
        anchorMedian = anchorSummary.q3;
        break;
      default:
        podMedian = dwellSummary.podMedian;
        polMedian = dwellSummary.polMedian;
        berthingMedian = berthingSummary.median;
        anchorMedian = anchorSummary.median;
    }
    vesselCount = (summary[vesselR] || ({} as any)).vessels;
  }

  const unselectPort = () => {
    // onRemove();
    onSelectPort && onSelectPort(undefined);
    onMapSelectPort && onMapSelectPort(undefined);
  };

  return (
    <div>
      {selectedPort && (
        <section className={classes.wrapper}>
          <Grid
            container
            direction="column"
            alignItems="center"
            className={classes.wrapper}
          >
            <div className={classes.popupRoot}>
              <div className={classes.cardsHeader}>
                <div className={classes.cardsHeaderTitle}>
                  <Typography
                    gutterBottom
                    variant="h6"
                    color="textSecondary"
                    component="h2"
                    className={classes.cardsHeaderPortname}
                  >
                    {selectedPort?.name}
                  </Typography>
                  <small className={classes.cardsHeaderPortcode}>
                    ({selectedPort?.portUnLocode})
                  </small>
                </div>

                <IconButton aria-label="delete" onClick={unselectPort}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div className={classes.cardContentWrapper}>
                <div className={classes.detailList}>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader">
                        Container
                      </ListSubheader>
                    }
                    className={classes.rootList}
                  >
                    <ListItem>
                      <Grid
                        container
                        spacing={3}
                        className={classes.segmentBreakdownContainer}
                      >
                        <Grid
                          item
                          xs={6}
                          key="discharge"
                          className={classes.segmentBreakdownTitle}
                        >
                          Import Dwell
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          key="discharge-val"
                          className={classes.segmentBreakdown}
                        >
                          <span className={classes.segmentBreakdownVal}>
                            {podMedian !== null && !isNaN(podMedian)
                              ? `${Number(podMedian).toFixed(2)} Days`
                              : NO_DATA_MSG}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListItem>
                      <Grid
                        container
                        spacing={3}
                        className={classes.segmentBreakdownContainer}
                      >
                        <Grid
                          item
                          xs={6}
                          key="loading"
                          className={classes.segmentBreakdownTitle}
                        >
                          Export Dwell
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          key="loading-val"
                          className={classes.segmentBreakdown}
                        >
                          <span className={classes.segmentBreakdownVal}>
                            {polMedian !== null && !isNaN(polMedian)
                              ? `${Number(polMedian).toFixed(2)} Days`
                              : NO_DATA_MSG}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListSubheader component="div" id="nested-list-subheader">
                      Vessels
                    </ListSubheader>

                    <ListItem>
                      <Grid
                        container
                        spacing={3}
                        className={classes.segmentBreakdownContainer}
                      >
                        <Grid
                          item
                          xs={6}
                          key="loading"
                          className={classes.segmentBreakdownTitle}
                        >
                          Berthing Dwell
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          key="loading-val"
                          className={classes.segmentBreakdown}
                        >
                          <span className={classes.segmentBreakdownVal}>
                            {berthingMedian !== null && !isNaN(berthingMedian)
                              ? `${Number(berthingMedian).toFixed(2)} Days`
                              : NO_DATA_MSG}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListItem>
                      <Grid
                        container
                        spacing={3}
                        className={classes.segmentBreakdownContainer}
                      >
                        <Grid
                          item
                          xs={6}
                          key="loading"
                          className={classes.segmentBreakdownTitle}
                        >
                          Anchor Dwell
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          key="loading-val"
                          className={classes.segmentBreakdown}
                        >
                          <span className={classes.segmentBreakdownVal}>
                            {anchorMedian !== null && !isNaN(anchorMedian)
                              ? `${Number(anchorMedian).toFixed(2)} Days`
                              : NO_DATA_MSG}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <ListItem>
                      <Grid
                        container
                        spacing={3}
                        className={classes.segmentBreakdownContainer}
                      >
                        <Grid
                          item
                          xs={6}
                          key="loading"
                          className={classes.segmentBreakdownTitle}
                        >
                          Vessel Count
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          key="loading-val"
                          className={classes.segmentBreakdown}
                        >
                          <span className={classes.segmentBreakdownVal}>
                            {vesselCount !== null && !isNaN(vesselCount)
                              ? `${Number(vesselCount).toFixed(0)} Vessels`
                              : NO_DATA_MSG}
                          </span>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </List>
                </div>
              </div>
            </div>
          </Grid>
        </section>
      )}
    </div>
  );
}
