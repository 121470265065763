import mapboxgl from "mapbox-gl";

import { createContext, useState, useEffect } from "react";
import { MapSummary, mapCenterCoord, LngLatLike } from "./utils/constants";
import { flyTo } from "./utils/map/flyTo";

let localMap = null;
let isActiveSearch = false;

enum IndicatorStatus {
  active = 0,
  inactive = 1,
}

interface AppContextInterface {
  mapEl: React.MutableRefObject<mapboxgl.Map | null>;
  currentPosition: LngLatLike;
  onUpdatePosition?: (position: LngLatLike) => void;
  onUpdateIndicatorStatus?: (status: IndicatorStatus) => void;
  onUpdateIndicatorPos?: (position: LngLatLike) => void;
  onMapSelectPort?: (summary: MapSummary | undefined) => void;
  setMapEl: (map: React.MutableRefObject<mapboxgl.Map>) => void;
}

const initialValues = {
  mapEl: null,
  currentPosition: mapCenterCoord,
  onUpdatePosition: () => {},
  onUpdateIndicatorStatus: () => {},
  onUpdateIndicatorPos: () => {},
  onMapSelectPort: () => {},
  setMapEl: () => {},
};

const MapContext = createContext<AppContextInterface>(initialValues);

const MapContextProvider = ({ children }: any) => {
  const [currentPosition, onUpdateCurrentPositionCX] = useState<LngLatLike>(
    initialValues.currentPosition
  );
  const [isIndicatorActive, onUpdateIndicatorStateCX] =
    useState<IndicatorStatus>(IndicatorStatus.inactive);

  const [mapEl, onUpdateMapElCX] =
    useState<React.MutableRefObject<mapboxgl.Map | null>>(null);

  const setMapEl = (map: any) => {
    onUpdateMapElCX(map);
    localMap = map;
  };

  const onUpdatePosition = (position: LngLatLike) => {
    onUpdateCurrentPositionCX(position);
  };

  const onUpdateIndicatorStatus = (status: IndicatorStatus) => {
    onUpdateIndicatorStateCX(status);
  };

  const onUpdateIndicatorPos = (position: LngLatLike) => {
    onUpdateCurrentPositionCX(position);
  };

  const onMapSelectPort = (summary: MapSummary | undefined) => {
    if (summary) {
      const position: LngLatLike = [summary.longitude, summary.latitude];
      onUpdatePosition(position);

      if (localMap) {
        onUpdateIndicatorStatus(IndicatorStatus.active);
        onUpdateIndicatorPos(position);
        
        flyTo(localMap, position, isActiveSearch);
        isActiveSearch = true;
      }
    } else {
      isActiveSearch = false;
      onUpdateIndicatorStatus(IndicatorStatus.inactive)
      flyTo(localMap, null, isActiveSearch);
    }
  };

  return (
    <MapContext.Provider
      value={{
        mapEl,
        currentPosition,
        onUpdatePosition,
        onUpdateIndicatorStatus,
        onUpdateIndicatorPos,
        onMapSelectPort,
        setMapEl,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContextProvider, MapContext };
