import React, { createContext, useState, useEffect } from "react";
import {
  FeatureCollection,
  MapSummary,
  Metric,
  Category,
  Resolution,
  MapAPIResponse,
} from "./utils/constants";
import { generateFeatureCollection } from "./utils/calc/generators";


const BASE_URL = process.env.PUBLIC_URL;

interface AppContextInterface {
  dataset: Array<any | undefined>;
  selectedPort?: MapSummary | undefined;
  selectedDateRange: Resolution;
  selectedCategory: Category;
  selectedMetric: Metric;
  isPortSelected: boolean;
  mapApiResponse: Array<any>;
  onUpdateCategory?: (categroy: Category) => void;
  onUpdateMetric?: (metric: Metric) => void;
  onSelectPort?: (summary: MapSummary | undefined) => void;
}

const initialValues = {
  dataset: [],
  selectedCategory: Category.Import,
  selectedMetric: Metric.Median,
  selectedDateRange: Resolution.LM,
  isPortSelected: false,
  mapApiResponse: [],
  onUpdateCategory: () => {},
  onUpdateMetric: () => {},
  onUpdateDatasetLoadedState: () => {},
  onSelectPort: () => {},
};

const DataContext = createContext<AppContextInterface>(initialValues);

const DataContextProvider = ({ children }: any) => {
  const [dataset, onUpdateDatasetCX] =
    useState<Array<FeatureCollection | undefined>>([]);
  const [selectedMetric, onUpdateSelectedMetricCX] = useState<Metric>(
    initialValues.selectedMetric
  );
  const [selectedPort, onUpdateSelectedPortCX] = useState<
    MapSummary | undefined
  >();
  const [selectedCategory, onUpdateSelectedCategoryCX] = useState<Category>(
    initialValues.selectedCategory
  );
  const [selectedDateRange, onUpdateSelectedDateRangeCX] = useState<Resolution>(
    initialValues.selectedDateRange
  );
  const [isPortSelected, onUpdateIsPortSelected] = useState<boolean>(false);
  const [mapApiResponse, onUpdateMapApiResponseCX] = useState<
    Array<MapAPIResponse | undefined>
  >([]);

  const onUpdateCategory = (category: Category) => {
    onUpdateSelectedCategoryCX && onUpdateSelectedCategoryCX(category);
    const mapFormatData = generateFeatureCollection(
      mapApiResponse,
      selectedMetric,
      category,
      selectedDateRange
    );
    onUpdateDatasetCX(mapFormatData);
  };

  const onUpdateMetric = (metric: Metric) => {
    onUpdateSelectedMetricCX && onUpdateSelectedMetricCX(metric);
    const mapFormatData = generateFeatureCollection(
      mapApiResponse,
      metric,
      selectedCategory,
      selectedDateRange
    );
    onUpdateDatasetCX(mapFormatData);
  };

  const onUpdateMapApiResponse = (data: Array<any>) => {
    onUpdateMapApiResponseCX(data);
    const formattedFeatureCollection = generateFeatureCollection(
      data,
      selectedMetric,
      selectedCategory,
      selectedDateRange
    );
    onUpdateDatasetCX(formattedFeatureCollection);
  };

  const onSelectPort = (summary: MapSummary | undefined) => {
    if (summary !== undefined) {
      onUpdateIsPortSelected(true);
    } else {
      onUpdateIsPortSelected(false);
    }
    onUpdateSelectedPortCX && onUpdateSelectedPortCX(summary);
  };

  const getData = (): Promise<Response> => {
    return fetch(`${BASE_URL}/data/congestion.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  };

  useEffect(() => {
    async function fetchMyAPI() {
      let res = await getData();
      let resData: Array<MapAPIResponse> = await res.json();
      onUpdateMapApiResponse(resData);
    }
    fetchMyAPI();
  }, []);


  useEffect(() => {
    const mapFormatData = generateFeatureCollection(
      mapApiResponse,
      selectedMetric,
      selectedCategory,
      selectedDateRange
    );
    onUpdateDatasetCX(mapFormatData);
  }, [mapApiResponse]);


  
  return (
    <DataContext.Provider
      value={{
        dataset,
        selectedPort,
        selectedDateRange,
        selectedCategory,
        selectedMetric,
        isPortSelected,
        mapApiResponse,
        onUpdateCategory,
        onUpdateMetric,
        onSelectPort,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContextProvider, DataContext };
