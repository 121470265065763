import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { zIndexLevels } from "./../../utils/constants";
import MetricSelect from "./../common/Distributors/Metric";
import CategoryRadioGroup from "./../common/Filters/Category";
import useDeviceDetect from "./../../utils/hooks/useDeviceDetect";
import Typography from "@material-ui/core/Typography";

import Legend from "./../Legend";
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    position: "absolute",
    bottom: "0",
    zIndex: zIndexLevels.panels,
    display: "flex",
    alignItems: "center",
    background: "#ffffff",
    justifyContent: "space-around",
    flexFlow: "column-reverse",
    borderRadius: 2,

    [theme.breakpoints.up("md")]: {
      flexFlow: "row",
      width: "70%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: 1124,
    },

    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
  },
  distributionFilter: {
    flex: "1 1 100%",
    display: "flex",
    justifyContent: "start",
    marginBottom: "10px",

    [theme.breakpoints.up("md")]: {
      maxWidth: "300px",
      padding: theme.spacing(1),
      marginBottom: "0px",
    },
  },
  distribution: {
    flex: "1 1 100%",
    width: "100%",

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1),
    },
  },
  metricMobileWrapper: {
    paddingBottom: theme.spacing(2),
    width: "100%",
  },
  categoryMobileWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  mobileLegendLabel: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
  },
  mobileLegendLabelTag: {
    margin: `0 ${theme.spacing(1)}px`,
    fontSize: 13,
  },
}));

export default function DistributionPanel(props: any) {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect();

  return (
    <section className={classes.wrapper}>
      <article className={classes.distributionFilter}>
        {isMobile ? (
          <article className={classes.categoryMobileWrapper}>
            <CategoryRadioGroup />
          </article>
        ) : (
          <MetricSelect />
        )}
      </article>

      {isMobile && (
        <section className={classes.mobileLegendLabel}>
          <span className={classes.mobileLegendLabelTag}>Low Dwell</span>
          <span className={classes.mobileLegendLabelTag}>High Dwell</span>
        </section>
      )}

      <article className={classes.distribution}>
        <Legend />
      </article>

      {isMobile && (
        <article className={classes.metricMobileWrapper}>
          <MetricSelect />
        </article>
      )}
    </section>
  );
}
