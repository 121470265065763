// import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import { Theme } from "@material-ui/core/styles";
import { useEffect, useContext, useRef } from "react";
import { DataContext } from "../../Data.context";
import { MapContext } from "../../Map.context";

import DetailsCard from "./../DetailsCard";
import Fade from "@material-ui/core/Fade";
import { useCurrentHeight } from "./../../utils/hooks/useResize";
import useDeviceDetect from "./../../utils/hooks/useDeviceDetect";
import { makeStyles } from "@material-ui/core/styles";

import {
  zIndexLevels,
  mapCenterCoord,
  LEGEND_COLORS_HEX,
  Breakpoints,
  FeatureCollection,
  cardBoxShadow,
  MapSummary,
} from "./../../utils/constants";

mapboxgl.accessToken =
  "pk.eyJ1IjoiYWpub2x0ZTEyIiwiYSI6ImNrdGMweWxoNjAzbngyd3Bmd2F0Y3Y3cnYifQ.lG00SMQtLvEmcNQV0yRVCg";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    width: "100%",
    minHeight: 500,
    transition: "width 2s, height 2s, background 2s, transform 2s",

    "& .mapboxgl-ctrl-logo": {
      display: "none !important",
    },
    "& .mapboxgl-popup-content": {
      boxShadow: cardBoxShadow,
      padding: theme.spacing(1),
    },
    "& .mapboxgl-map": {
      position: "absolute",
    },

    "& .mapboxgl-ctrl-bottom-right": {
      bottom: 170,

      [theme.breakpoints.up("md")]: {
        bottom: 76,
      },

      [theme.breakpoints.up("lg")]: {
        bottom: 76,
      },
    },

    "& .mapboxgl-ctrl-bottom-left": {
      bottom: 170,

      [theme.breakpoints.up("md")]: {
        bottom: 120,
      },

      [theme.breakpoints.up("lg")]: {
        bottom: 76,
      },
    },
  },
  mapContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: zIndexLevels.map,

    "& canvas": {
      background: "#d2d5d5",
    },
  },
}));

const useStylesWithProps = (props: any) =>
  makeStyles((theme: Theme) => ({
    wrapper: {
      position: "relative",
      width: "100%",
      height: `${props.height - 84}px`,
      minHeight: 500,
      transition: "width 2s, height 2s, background 2s, transform 2s",

      "& .mapboxgl-canvas-container": {
        cursor: "pointer",
      },

      "& .mapboxgl-ctrl-logo": {
        display: "none !important",
      },
      "& .mapboxgl-popup-content": {
        boxShadow: cardBoxShadow,
        padding: theme.spacing(1),
      },
      "& .mapboxgl-map": {
        position: "absolute",
      },

      "& .mapboxgl-ctrl-bottom-right": {
        bottom: 170,

        [theme.breakpoints.up("md")]: {
          bottom: 76,
        },

        [theme.breakpoints.up("lg")]: {
          bottom: 76,
        },
      },

      "& .mapboxgl-ctrl-bottom-left": {
        bottom: 170,

        [theme.breakpoints.up("md")]: {
          bottom: 120,
        },

        [theme.breakpoints.up("lg")]: {
          bottom: 76,
        },
      },
    },
  }));

interface Props {
  filtersHidden?: boolean;
}

export default function Map(props: Props) {
  let height = useCurrentHeight();
  let shouldAnimateIndicator = false;
  let indicatorWidth = 0.5;
  let start: number;
  let previousTimeStamp: number;
  let reqAnimframeId: number;

  const classes = useStyles();
  const { isMobile } = useDeviceDetect();
  const { wrapper } = useStylesWithProps({
    height: height,
  })();

  const mapContainer = useRef<null | HTMLDivElement>(null);

  if (mapContainer && mapContainer.current) {
    // If we are showing filters, take some height off the bottom to account for the 
    // space filters take at the top of the page.
    mapContainer.current.style.height = props.filtersHidden ? `${height}px` :`${height - 73}px`;
  }
  const map = useRef<mapboxgl.Map | null>(null);
  let geoFeatureCollection: any;

  const addMapControls = (map: mapboxgl.Map) => {
    const controls = new mapboxgl.NavigationControl();
    const fullScreen = new mapboxgl.FullscreenControl({
      container: document.querySelector("body"),
    });

    map.addControl(controls, "bottom-right");
    map.addControl(fullScreen, "bottom-right");
  };

  const {
    dataset,
    selectedPort,
    selectedCategory,
    selectedMetric,
    isPortSelected,
    onSelectPort,
  } = useContext(DataContext);

  const { currentPosition, mapEl, setMapEl, onMapSelectPort } =
    useContext(MapContext);

  const setMapGraphOpacity = () => {
    map &&
      map.current?.getLayer("plot") &&
      map &&
      map.current?.setPaintProperty("plot", "circle-opacity", 0.8);
  };

  const updateMap = (data: Array<FeatureCollection>) => {
    (map.current.getSource("port-data") as any).setData({
      type: "FeatureCollection",
      features: [...data],
    });
  };

  // Map
  const initMap = (data: Array<FeatureCollection>) => {
    const mapCls = new mapboxgl.Map({
      container: mapContainer.current as HTMLDivElement,
      style: "mapbox://styles/drew44/ckuzxfj8g0wu814nqxlt7ydni", // default styling 'mapbox://styles/mapbox/light-v10',
      center: currentPosition,
      zoom: 2,
      minZoom: 2,
      maxZoom: 15,
      pitch: 20,
      bearing: 0,
      attributionControl: false,
    });

    if (!isMobile) {
      addMapControls(mapCls);
    }

    mapCls.on("load", () => {
      addSource(data);
      addLayer();
    });

    mapCls.on("click", "plot", (e: any) => {
      const summary = e.features[0].properties as MapSummary;
      onSelectPort && onSelectPort(summary);
      onMapSelectPort && onMapSelectPort(summary);
    });

    mapCls.on("style.load", () => {
      const waiting = () => {
        if (!(map && map.current?.isStyleLoaded())) {
          setTimeout(waiting, 200);
        } else {
          // setIsMapLoaded(true);
          setTimeout(setMapGraphOpacity, 1000);
        }
      };
      waiting();
    });

    map.current = mapCls;

    setMapEl(map);
  };

  const addLayer = () => {
    map &&
      map.current?.addLayer({
        id: "plot",
        type: "circle",
        source: "port-data",
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            ["*", 1, ["get", "size"]],
          ],
          "circle-color": [
            "step",
            ["get", "colorSegment"],
            "#888",
            0,
            LEGEND_COLORS_HEX[0],
            1,
            LEGEND_COLORS_HEX[1],
            2,
            LEGEND_COLORS_HEX[2],
            3,
            LEGEND_COLORS_HEX[3],
            4,
            LEGEND_COLORS_HEX[4],
            5,
            LEGEND_COLORS_HEX[5],
            100,
            "#000",
          ],
          "circle-opacity": 0,
          "circle-opacity-transition": { duration: 1000 },
        },
      });
  };

  const addSource = (data: Array<FeatureCollection>) => {
    geoFeatureCollection = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [...data],
      },
    };

    map && map.current?.addSource("port-data", geoFeatureCollection);
  };

  const addUpdateMap = (data: Array<FeatureCollection>) => {
    if (map && map.current) {
      if (map.current.getSource("port-data")) {
        updateMap(data);
        return;
      } else {
        addSource(data);
      }
    } else {
      initMap(data);
    }
  };

  // Active Indicator
  const addActiveIndicator = (summary: MapSummary) => {
    map &&
      map.current?.addSource("active-point", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {
                size: summary.size,
                colorSegment: summary.colorSegment,
              },
              geometry: {
                type: "Point",
                coordinates: [summary.longitude, summary.latitude], // icon position [lng, lat]
              },
            },
          ],
        },
      });
    map &&
      map.current?.addLayer({
        id: "active-point-layer",
        type: "circle",
        source: "active-point",
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            0,
            ["*", 1, ["get", "size"]],
          ],
          "circle-color": [
            "step",
            ["get", "colorSegment"],
            "#888",
            0,
            LEGEND_COLORS_HEX[0],
            1,
            LEGEND_COLORS_HEX[1],
            2,
            LEGEND_COLORS_HEX[2],
            3,
            LEGEND_COLORS_HEX[3],
            4,
            LEGEND_COLORS_HEX[4],
            5,
            LEGEND_COLORS_HEX[5],
            100,
            "#000",
          ],
          "circle-stroke-color": "#f7f7f7",
          "circle-stroke-width": 0.5,
          "circle-opacity": 0.9,
        },
      });
  };

  const updateActiveIndicator = (activeSource: any, summary: MapSummary) => {
    const data = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            size: summary.size,
            colorSegment: summary.colorSegment,
          },
          geometry: {
            type: "Point",
            coordinates: [summary.longitude, summary.latitude],
          },
        },
      ],
    };
    activeSource.setData(data);
  };

  function stepIndicatorRadius(timestamp: number) {
    if (start === undefined) start = timestamp;
    // const elapsed = timestamp - start;
    if (previousTimeStamp !== timestamp && shouldAnimateIndicator) {
      // const count = Math.min(1 * elapsed, 0);
      map &&
        map.current?.setPaintProperty(
          "active-point-layer",
          "circle-stroke-width",
          indicatorWidth / 2
        );
      map &&
        map.current?.setPaintProperty(
          "active-point-layer",
          "circle-stroke-opacity",
          0.5
        );
      indicatorWidth = ++indicatorWidth % 60;
      previousTimeStamp = timestamp;
      reqAnimframeId = requestAnimationFrame(stepIndicatorRadius);
    }
  }

  const onRemove = () => {
    cancelAnimationFrame(reqAnimframeId);

    map &&
      map.current?.setPaintProperty(
        "active-point-layer",
        "circle-stroke-width",
        0
      );
    map &&
      map.current?.setPaintProperty(
        "active-point-layer",
        "circle-stroke-opacity",
        0
      );
  };

  useEffect(() => {
    if (!(dataset.length > 0) || !map) return;
    addUpdateMap(dataset);
    return;
  }, [dataset]);

  useEffect(() => {
    if (reqAnimframeId) {
      cancelAnimationFrame(reqAnimframeId);
    }

    const activeSource = mapEl && mapEl.current?.getSource("active-point");

    if (!isPortSelected) {
      shouldAnimateIndicator = false;
      if (activeSource) {
        onRemove();
      }

      return;
    } else {
      const summary = (dataset as Array<FeatureCollection>).find(
        (a: FeatureCollection) =>
          a.properties?.portUnLocode === selectedPort?.portUnLocode
      );
      const portSummary = (summary?.properties || {}) as MapSummary;

      if (!activeSource) {
        addActiveIndicator(portSummary);
      } else {
        updateActiveIndicator(activeSource, portSummary);
      }

      shouldAnimateIndicator = true;
      reqAnimframeId = requestAnimationFrame(stepIndicatorRadius);
    }
  }, [selectedPort, selectedCategory, selectedMetric, isPortSelected]);

  return (
    <section className={wrapper}>
      {isPortSelected && <DetailsCard />}

      <div ref={mapContainer} className={classes.mapContainer} />
    </section>
  );
}
