import { useContext } from "react";

import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";

import { DataContext } from "../../../../Data.context";

import { zIndexLevels } from "./../../../../utils/constants";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: "100%",
    position: "relative",
    backgroundColor: "#ffffff",
    zIndex: zIndexLevels.panels,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0, 1),

    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  formWrapper: {
    display: "flex",
    flexDirection:'row'
  },
  formCheckbox: {},
  formCheckboxEnd: {},
}));

export default function CategoryRadioGroup(props: any) {
  const classes = useStyles();
  const { selectedCategory, onUpdateCategory } = useContext(DataContext);

  const handleCategorysUpdate = (e: any) => {
    const val = e.currentTarget.value;
    onUpdateCategory && onUpdateCategory(val)
  };

  return (
    <section className={classes.wrapper}>
      <FormGroup>
        <RadioGroup
          onChange={handleCategorysUpdate}
          value={selectedCategory}
          className={classes.formWrapper}
        >
          <FormControlLabel
            className={classes.formCheckbox}
            value="Import"
            control={<Radio />}
            label="Import"
          />
          <FormControlLabel
            className={classes.formCheckbox}
            value="Export"
            control={<Radio />}
            label="Export"
          />

          <Divider light orientation="vertical" flexItem />

          <FormControlLabel
            className={classes.formCheckboxEnd}
            value="Berthing"
            control={<Radio />}
            label="Berthing"
          />

          <FormControlLabel
            className={classes.formCheckbox}
            value="Anchor"
            control={<Radio />}
            label="Anchor"
          />
        </RadioGroup>
      </FormGroup>
    </section>
  );
}
