import React, { useContext } from "react";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import {
  Metric,
  MetricReadable,
} from "./../../../../utils/constants";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { DataContext } from "../../../../Data.context";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: "100%",
    width: "100%",
    position: "relative",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    "& .MuiSelect-icon.Mui-disabled": {
      display: "none",
    },

    "& .MuiInput-underline:before": {
      borderBottom: "0px none",
    },

    "& label + .MuiInput-formControl": {
      marginLeft: theme.spacing(2),
    },

    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      left: 4,
    },
    "& .MuiSelect-select:focus": {
      background: "transparent",
    },
  },
  formControl: {
    width: "100%",
  },
  dropdownStyle: {},
}));

export default function MetricSelect(props: any) {
  const classes = useStyles();
  const { selectedMetric, onUpdateMetric } = useContext(DataContext);

  const getFilterOpt = () => {
    return [
      { label: MetricReadable.Median, value: Metric.Median },
      { label: MetricReadable.Quantile25, value: Metric.Quantile25 },
      { label: MetricReadable.Quantile75, value: Metric.Quantile75 },
    ];
  };

  const handleColorChange = (event: React.SyntheticEvent<EventTarget>) => {
    const el = event?.target as any;
    onUpdateMetric && onUpdateMetric(el.value);
  };

  return (
    <section className={classes.wrapper}>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="color-filter">Color</InputLabel>
        <Select
          disabled={props.isDisabled}
          labelId="color-filter"
          id="color-filter"
          value={selectedMetric}
          onChange={handleColorChange}
          label="Color"
          variant="standard"
          MenuProps={{ classes: { paper: classes.dropdownStyle } }}
        >
          {getFilterOpt().map(({ label, value }) => {
            return (
              <MenuItem key={value.toString()} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </section>
  );
}
