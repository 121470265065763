import {
  FeatureCollection,
  MapAPIResponse,
  Metric,
  Category,
  Resolution,
  Quantile,
  legendSegmentPercentageMapping,
} from "../constants";
import { normalizeNx } from "./algo";
import { ascSort } from "./sorting";

/* Category - Loading / Discharge / Berthing / Anchor */
const getAPIResponseMedianByCategory = (
  category: Category,
  resolution: Resolution
) => {
  let mapping = "";
  switch (category) {
    case Category.Import:
      // Discharge
      mapping = `dwell${resolution}.podMedian`;
      break;
    case Category.Export:
      // Loading
      mapping = `dwell${resolution}.polMedian`;
      break;
    case Category.Berthing:
      mapping = `berthing${resolution}.median`;
      break;
    case Category.Anchor:
      mapping = `anchor${resolution}.median`;
      break;
    case Category.Vessel:
      mapping = `within50nmi${resolution}.vesselsMooredHere`;
      break;
    default:
      console.warn(
        `Sorry, no api mapping for exists ${category} inside config.ts .`
      );
  }
  return mapping;
};

const getAPIResponseQuantileByCategory = (
  category: Category,
  resolution: Resolution,
  quantile: Quantile
) => {
  let mapping = "";
  switch (category) {
    case Category.Import:
      if (quantile === Quantile.low) {
        // do something for 25%
        mapping = `dwell${resolution}.podQ1`;
      } else {
        // do something for 75%
        mapping = `dwell${resolution}.podQ3`;
      }
      // Discharge

      break;
    case Category.Export:
      // Loading
      if (quantile === Quantile.low) {
        // do something for 25%
        mapping = `dwell${resolution}.polQ1`;
      } else {
        // do something for 75%
        mapping = `dwell${resolution}.polQ3`;
      }

      break;
    case Category.Berthing:
      if (quantile === Quantile.low) {
        // do something for 25%
        mapping = `berthing${resolution}.q1`;
      } else {
        // do something for 75%
        mapping = `berthing${resolution}.q3`;
      }

      break;
    case Category.Anchor:
      if (quantile === Quantile.low) {
        // do something for 25%
        mapping = `anchor${resolution}.q1`;
      } else {
        // do something for 75%
        mapping = `anchor${resolution}.q3`;
      }

      break;
    default:
      console.warn(
        `Sorry, no api quantile mapping for exists for ${category} inside config.ts .`
      );
  }
  return mapping;
};

const getAPIResponseNByCategory = (
  category: Category,
  resolution: Resolution
) => {
  let mapping = "";
  switch (category) {
    case Category.Import:
      // Discharge
      mapping = `dwell${resolution}.podN`;
      break;
    case Category.Export:
      // Loading
      mapping = `dwell${resolution}.polN`;
      break;
    case Category.Berthing:
      mapping = `berthing${resolution}.n`;
      break;
    case Category.Anchor:
      mapping = `anchor${resolution}.n`;
      break;
    case Category.Vessel:
      mapping = `within50nmi${resolution}.vesselsInArea`;
      break;
    default:
      console.warn(
        `Sorry, no api mapping for exists ${category} inside config.ts .`
      );
  }
  return mapping;
};

const getMappingArrayByMetric = (
  metric: Metric,
  category: Category,
  resolution: Resolution
) => {
  let mappingArrayByMetric: Array<string> = [""];
  switch (metric) {
    case Metric.Median:
      mappingArrayByMetric = getAPIResponseMedianByCategory(
        category,
        resolution
      ).split(".");

      break;
    case Metric.Quantile25:
      mappingArrayByMetric = getAPIResponseQuantileByCategory(
        category,
        resolution,
        Quantile.low
      ).split(".");
      break;
    case Metric.Quantile75:
      mappingArrayByMetric = getAPIResponseQuantileByCategory(
        category,
        resolution,
        Quantile.high
      ).split(".");
      break;
    default:
      console.warn(`Sorry, no metrics found for ${metric}.`);
  }
  return mappingArrayByMetric;
};

//
const getMaxMinObservationsByCategory = (
  data: Array<MapAPIResponse | undefined>,
  selectedCategory: Category,
  resolution: Resolution
) => {
  const numObservationMappingArr = getAPIResponseNByCategory(
    selectedCategory,
    resolution
  ).split(".");

  if (data && data.length > 0) {
    const arrayCc = data.map((d: MapAPIResponse | undefined): number => {
      const numObservations =
        ((d as any)[numObservationMappingArr[0]] || ({} as any))[
          numObservationMappingArr[1]
        ] || 0;
      return numObservations;
    });
    arrayCc.sort(ascSort);
    const MinObservationDwell = arrayCc[0];
    const MaxObservationDwell = arrayCc[arrayCc.length - 1];
    return { MaxObservationDwell, MinObservationDwell };
  }
  return { MaxObservationDwell: 0, MinObservationDwell: 0 };
};

const cleanseDwellArr = (dataSet: Array<number>): Array<number> => {
  return dataSet.filter(function (dwellInDays) {
    return dwellInDays !== undefined;
  });
};

const getArrayOfDwellFromApiResponse = (
  dataSet: Array<MapAPIResponse | undefined>,
  mapping: Array<any>
) => {
  const dwellArr = dataSet.map((d: MapAPIResponse | undefined) => {
    const dwell = ((d as any)[mapping[0]] || ({} as any))[mapping[1]];

    if (dwell !== null && Number.isFinite(dwell)) {
      return dwell;
    }
  });

  return cleanseDwellArr(dwellArr);
};

const generateColorSegment = (
  summary: MapAPIResponse | undefined,
  arrayDwell: Array<number>,
  metric: Metric,
  category: Category,
  resolution: Resolution
) => {
  const _summary = summary as any; // casted to any for dynamic value
  let noLimitFound = true;

  const dwellByMetricMapping = getMappingArrayByMetric(
    metric,
    category,
    resolution
  );

  let maxIndex = 0;
  const val = (_summary[dwellByMetricMapping[0]] || ({} as any))[
    dwellByMetricMapping[1]
  ];

  if (isNaN(val) || !val) {
    return 100;
  }

  for (let i = 0; i < arrayDwell.length; i++) {
    if (!isNaN(val)) {
      if (val <= arrayDwell[i] && noLimitFound) {
        noLimitFound = false;
        maxIndex = i;
      }
    }
  }

  const percentile = (maxIndex / arrayDwell.length) * 100;

  let colorSegment = 99;
  let limitSegNotFound = true;
  for (let j = 0; j < legendSegmentPercentageMapping.length; j++) {
    const upperLimit = legendSegmentPercentageMapping[j][1];
    const lowerLimit = legendSegmentPercentageMapping[j][0];
    if (percentile >= lowerLimit) {
      if (percentile <= upperLimit) {
        //
        if (limitSegNotFound) {
          colorSegment = j;
          limitSegNotFound = false;
        }
      }
    }
  }
  return colorSegment;
};

export const generateFeatureCollection = (
  data: Array<MapAPIResponse | undefined>,
  metric: Metric,
  category: Category,
  resolution: Resolution
): Array<FeatureCollection> => {
  const { MaxObservationDwell, MinObservationDwell } =
    getMaxMinObservationsByCategory(data, category, resolution);

  // Get Dwell mapping to api response
  const dwellByMetricMapping = getMappingArrayByMetric(
    metric,
    category,
    resolution
  );

  // Get Dwell and remove nulls/undefined properties for calc
  const arrDwellDays = getArrayOfDwellFromApiResponse(
    data,
    dwellByMetricMapping
  );

  const sortedArray = arrDwellDays.sort(ascSort);

  // Get each individual metric mapping to build collection

  const numObservationMappingArr = getAPIResponseNByCategory(
    category,
    resolution
  ).split(".");

  const dwellMappingArr = getMappingArrayByMetric(
    Metric.Median,
    category,
    resolution
  );

  const quantileLowMappingArr = getMappingArrayByMetric(
    Metric.Quantile25,
    category,
    resolution
  );
  const quantileHighMappingArr = getMappingArrayByMetric(
    Metric.Quantile75,
    category,
    resolution
  );

  return data.map((port: MapAPIResponse | undefined) => {
    // Build Feature Collection
    const nx =
      ((port as any)[numObservationMappingArr[0]] || ({} as any))[
        numObservationMappingArr[1]
      ] || 0;

    // Calc
    const sizeByObservations = normalizeNx(
      nx,
      MinObservationDwell,
      MaxObservationDwell
    );
    const colorSegment = generateColorSegment(
      port,
      sortedArray,
      metric,
      category,
      resolution
    );

    //
    return {
      type: "Feature",
      properties: {
        nx: nx,
        latitude: port?.latitude || 0,
        longitude: port?.longitude || 0,
        name: port?.name || '',
        size: sizeByObservations,
        portUnLocode: port?.locode || '',
        q50:
          ((port as any)[dwellMappingArr[0]] || ({} as any))[
            dwellMappingArr[1]
          ] || null,
        q25:
          ((port as any)[quantileLowMappingArr[0]] || ({} as any))[
            quantileLowMappingArr[1]
          ] || null,
        q75:
          ((port as any)[quantileHighMappingArr[0]] || ({} as any))[
            quantileHighMappingArr[1]
          ] || null,
        colorSegment: colorSegment,
        vesselCount: port?.vesselsArriving,
      },
      geometry: {
        type: "Point",
        coordinates: [port?.longitude || 0, port?.latitude || 0],
      },
    };
  });

  return [];
};
