// Global
export const appBackground = '#FFFFFF';
export const loaderBgColor = '#0072ec';

// Card
export const cardBoxShadow =
  '0px 9px 12px rgba(0, 0, 0, 0.05), 0px 3px 16px rgba(0, 0, 0, 0.04), 0px 5px 6px rgba(0, 0, 0, 0.06)';
export const cardBorder = '1px solid #F0F1F2';
export const cardBackground = appBackground;
export const borderRadius = 2;

// Legend
export const LEGEND_COLORS_HEX = [
  '#094c72',
  '#026aa7',
  '#298fca',
  '#8bbdd9',
  '#f6ae2d',
  '#CA4236',
];