import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { zIndexLevels, cardBorder } from "./../../utils/constants";
import SearchAutoComplete from "./../common/Filters/Search";
import CategoryRadioGroup from "./../common/Filters/Category";
import useDeviceDetect from "./../../utils/hooks/useDeviceDetect";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 0,
    width: "100%",
    position: "relative",
    backgroundColor: "#ffffff",
    zIndex: zIndexLevels.panels,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "end",
    borderBottom: cardBorder,

    [theme.breakpoints.up("md")]: {},
  },
  start: {
    flex: "2 1 auto",
    display: "flex",
    justifyContent: "start",
    padding: theme.spacing(1),
    minWidth: "70%",

    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
    },

    "& .MuiDivider-vertical": {
      marginRight: theme.spacing(2),
    },
    "& section": {
      "&:last-of-type": {
        paddingLeft: theme.spacing(2),
      },
    },
  },
  end: {
    flex: "0 1 350px",
    display: "flex",
    padding: theme.spacing(1),
  },
  dateLabel : {
    
  }
}));

export default function FilterPanel(props: any) {
  const classes = useStyles();
  const { isMobile } = useDeviceDetect();

  return (
    <section className={classes.wrapper}>
      <article className={classes.start}>
        <SearchAutoComplete />

        {!isMobile ? <CategoryRadioGroup /> : null}
      </article>
      <article className={classes.end}>
        <Typography
                    gutterBottom
                    variant="body2"
                    color="textSecondary"
                    component="h2"
                    className={classes.dateLabel}
                  >
                    Last Month
                  </Typography>
      </article>
    </section>
  );
}
